import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const HowToRequestSection = styled.section`
  @media ${device.tablet} {
    min-height: 595px;
  }
  @media ${device.desktopLG} {
    min-height: 658px;
  }

  h2 {
    + p {
      font-family: "Sora", Helvetica, sans-serif;
    }
  }

  .react-multi-carousel-track {
    align-items: center;
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  .question {
    @media ${device.desktopXL} {
      font-size: 24px;
    }
  }

  .description {
    min-height: 140px;
    word-break: break-word;

    @media ${device.tablet} {
      min-height: auto;
    }
  }
`

export const TextCarousel = styled.div`
  min-height: 200px;
`
